import * as collectionview from '@collection-view/index';

export class LookingGlassLayout implements collectionview.CollectionViewLayout {
  static readonly DIMENSION = 250;

  public positions: collectionview.Position[];
  private sizes: collectionview.Size[];

  public view: collectionview.CollectionView;


  constructor(private items, private marginHorizontal: number, private marginVertical: number, private spacing: number) {

  }

  public performLayout(containerSize: collectionview.Size) {
    this.positions = [];
    this.sizes = [];

    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    const centerX = 0;
    const centerY = 0;

    let ring = 0;
    let indexInRing = 0;
    let itemsInRing = 1;
    let itemsAtLeftOrRight = 0;
    let itemsAtTopOrBottom = 0;
    for (let c = 0; c < this.items.length; c++) {
      let rowOffset = 0;
      let colOffset = 0;
      if (indexInRing < itemsInRing) {
        if (ring !== 0) {
          if (indexInRing < itemsAtLeftOrRight) {
            const indexInSide = indexInRing;
            // right
            rowOffset = -(Math.floor((itemsAtLeftOrRight - 1) / 2)) + indexInSide;
            colOffset = ring + -Math.abs(Math.floor(itemsAtLeftOrRight / 2) - indexInSide) * 0.5;
          } else if (indexInRing >= itemsAtLeftOrRight && indexInRing < itemsAtLeftOrRight + itemsAtTopOrBottom) {
            const indexInSide = indexInRing - itemsAtLeftOrRight;
            // bottom
            rowOffset = ring;
            colOffset = Math.floor(itemsAtTopOrBottom / 2) + (ring % 2 === 0 ? 0 : -0.5) - indexInSide;
          } else if (indexInRing >= itemsAtLeftOrRight + itemsAtTopOrBottom && indexInRing <= itemsInRing - itemsAtTopOrBottom) {
            // left
            const indexInSide = indexInRing - (itemsAtLeftOrRight + itemsAtTopOrBottom);
            // right
            rowOffset = ((itemsAtLeftOrRight - 1) / 2) - indexInSide;
            colOffset = -ring + Math.abs(Math.floor(itemsAtLeftOrRight / 2) - indexInSide) * 0.5;
          } else {
            // top
            const indexInSide = indexInRing - (itemsInRing - itemsAtTopOrBottom);

            rowOffset = -ring;
            colOffset = -Math.floor(itemsAtTopOrBottom / 2) + (ring % 2 === 0 ? 0 : 0.5) + indexInSide;
          }
        }

        const position = new collectionview.Position(centerX - LookingGlassLayout.DIMENSION / 2
          + colOffset * (LookingGlassLayout.DIMENSION + this.spacing),
          centerY  - LookingGlassLayout.DIMENSION / 2 + rowOffset * (LookingGlassLayout.DIMENSION + this.spacing));
        this.positions.push(position);

        maxX = Math.max(maxX, position.x + LookingGlassLayout.DIMENSION);
        maxY = Math.max(maxY, position.y + LookingGlassLayout.DIMENSION);
        minX = Math.min(minX, position.x);
        minY = Math.min(minY, position.y);

        indexInRing++;
      } else {
        ring++;
        itemsInRing = ring * 6;
        indexInRing = 0;
        itemsAtLeftOrRight = 1 + (ring - 1) * 2;
        itemsAtTopOrBottom = 1 + ring;

        c--;
      }
    }

    const firstResult = new collectionview.Size(2 * this.marginHorizontal + Math.abs(maxX - minX),
      2 * this.marginVertical + Math.abs(maxY - minY));

    const result = new collectionview.Size(Math.max(firstResult.width, containerSize.width),
      Math.max(firstResult.height, containerSize.height));

    const positionsNew = [];

    const xOffset = -(minX + firstResult.width / 2) + this.marginHorizontal;
    const yOffset = -(minY + firstResult.height / 2) + this.marginVertical;

    this.positions = this.positions.map(pos => new collectionview.Position(pos.x + xOffset + result.width / 2,
      pos.y + yOffset + result.height / 2));

    /*const position = new collectionview.Position(this.marginHorizontal + offsetX + (insertIndex * (itemWidth + this.spacing)),
         lowestY);

    this.positions.push(position);
    this.sizes.push(new collectionview.Size(itemWidth, itemHeight));
*/
    return result;
  }

  getContentSize(count: number, containerSize: collectionview.Size): collectionview.Size {
    return this.performLayout(containerSize);
  }

  updateContainerSize(containerSize: collectionview.Size) {
    console.log('updateContainerSize');
    this.performLayout(containerSize);
  }

  getIndices(ranges: collectionview.Ranges, count: number, containerSize: collectionview.Size): number[] {
    const result = [];

    //console.log(this.view.scrollPosition);

    const x1 = this.view.scrollPosition.x - containerSize.width / 4;
    const x2 = this.view.scrollPosition.x + containerSize.width * 1.25;

    const y1 = this.view.scrollPosition.y - containerSize.height / 4;
    const y2 = this.view.scrollPosition.y + containerSize.height * 1.25;

    const size = new collectionview.Size(LookingGlassLayout.DIMENSION, LookingGlassLayout.DIMENSION);
    for (let i = 0; i < this.items.length; i++) {
      if (this.view && this.view.scrollPosition.x !== 0 && this.view.scrollPosition.y !== 0) {
        const pos = this.positions[i];

        //console.log(pos);

        //console.log(i + ': ' + (pos.x > x1 && pos.x < x2) + ' ' + (pos.x + size.width > x1 && pos.x + size.width < x2) + ' ' +
        //    (pos.y > y1 && pos.y < y2) + ' ' + (pos.y + size.height > y1 && pos.y + size.height < y2));

        if (((pos.x > x1 && pos.x < x2) || (pos.x + size.width > x1 && pos.x + size.width < x2)) &&
            ((pos.y > y1 && pos.y < y2) || (pos.y + size.height > y1 && pos.y + size.height < y2))) {
          result.push(i);
        }
      } else {
        result.push(i);
      }
    }

    //console.log(this.view + ' ' + result.length + ' von ' + this.items.length);

    return result;
  }

  getElementPosition(index: number): collectionview.Position {
    return this.positions[index];
  }

  configureElement(element: HTMLElement, index: number) {
    const {width, height} = new collectionview.Size(LookingGlassLayout.DIMENSION, LookingGlassLayout.DIMENSION); // this.sizes[index];
    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
  }

  convertPositionInSize(position: collectionview.Position, newContainerSize: collectionview.Size,
      oldLayout: collectionview.CollectionViewLayout): collectionview.Position {
    return position;
  }

  getElementInfo(index: number): any {
    return this.items[index];
  }
}
