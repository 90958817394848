import * as collectionview from '@collection-view/index';
import { LookingGlassLayout } from './lookingglasslayout';

declare var $: any;

export class Delegate {

  private scrolling = false;
  private lastScrollTime = 0;
  private animationHandle = -1;

  constructor(private items) {
  }

  getCount() {
    return this.items.length;
  }

  configureElement(element: Element, index) {
    element.classList.add('box');
    element.setAttribute('index', index);

    $(element).html(index + '.');

    if ($(element).find('.inlay').length === 0) {
      $(element).append('<img class=inlay />');
    }
    $(element).find('img').attr('src', 'https://phrase1.schrage.it/img/small_' + this.items[index].strippedContent + '.jpg');

    //$(element).find('img').attr('src', 'https://www.phrase1.de/img/phrasen/small_' + this.items[index].strippedContent + '.jpg');
  }

  /*getAnimation(index, info) {
    return new collectionview.Animation(200, (info.row + info.column) * 70);
  }*/

  onScroll(view: collectionview.CollectionView) {
    this.lastScrollTime = Date.now();
    if (!this.scrolling) {
      this.onScrollIntern(view);
      this.scrolling = true;
      const cb = (time: number) => {
        this.onScrollIntern(view);

        if (this.lastScrollTime < Date.now() - 1000) {
          this.scrolling = false;
          console.log('cancel frame');
        } else {
          this.animationHandle = window.requestAnimationFrame(cb);
        }
      };
      this.animationHandle = window.requestAnimationFrame(cb);
    }
  }

  onScrollIntern(view: collectionview.CollectionView) {
    const layout = <LookingGlassLayout>view.layout;

    const virtualCenterX = view.containerSize.width / 2 + view.scrollPosition.x;
    const virtualCenterY = view.containerSize.height / 2 + view.scrollPosition.y;

    const referenceLength = Math.max(view.containerSize.width, view.containerSize.height) / 2;

    for (let i = 0; i < this.getCount(); i++) {
      const pos = layout.getElementPosition(i);
      const deltaX = Math.abs(pos.x + LookingGlassLayout.DIMENSION / 2 - virtualCenterX);
      const deltaY = Math.abs(pos.y + LookingGlassLayout.DIMENSION / 2 - virtualCenterY);

      const maxDelta = Math.max(deltaX, deltaY);

      const fraction = 1 - Math.min(maxDelta, referenceLength) / referenceLength;

      $('.box[index=' + i + '] img').css('transform', 'scale(' + (0.75 + 0.5 * fraction) + ')');
    }
  }
}
