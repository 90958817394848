import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as collectionview from '@collection-view/index';
import { LookingGlassLayout } from './lookingglasslayout';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jsonstring } from '../jsonstring';
import { Delegate } from './delegate';

declare var $: any;

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss']
})
export class WallComponent implements OnInit {

  @ViewChild('scrollElement') scrollElement: ElementRef;

  private view: collectionview.CollectionView;

  constructor(private http: HttpClient) { }

  ngOnInit() {

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa('admin:IchB!nAuch3inAdm!n'));

    const data = jsonstring;
    this.load(data);

    /*this.http.get('https://www.phrase1.de/hundekuchen/api.php?processed', {headers: headers}).subscribe((response) => {
      this.load(response);
    });*/


  }

  private load(response: any) {
    const items = response;

    const layout = new LookingGlassLayout(items, 20, 20, 40);

    const delegate = new Delegate(items);
    this.view = new collectionview.CollectionView(
      this.scrollElement.nativeElement,
      layout,
      delegate
    );
    layout.view = this.view;
    this.view.updateLayout(layout);

    window.setTimeout(() => {
      const pos = layout.getElementPosition(0);
      $(this.scrollElement.nativeElement).parent().scrollTop(pos.y + LookingGlassLayout.DIMENSION / 2 - this.view.containerSize.height / 2);
      $(this.scrollElement.nativeElement).parent().scrollLeft(pos.x + LookingGlassLayout.DIMENSION / 2 - this.view.containerSize.width / 2);
    }, 500);
  }

}
